@import url(https://fonts.googleapis.com/css?family=Raleway:400,400i,900);


$colorBrandPrimary: #ef0202;
$colorBackgroundDark: #0a070e;
$colorBackgroundLight: #d6d4d8;
$colorBlack: #000;
$colorWhite: #f7f7f7;

body {
	background-color: $colorWhite;
	margin: 0;
	color: $colorBlack;
	font-family: 'Raleway', Arial, Helvetica, sans-serif;
	font-size: 1;
	//margin-left: 49px;
	@include breakpoint(medium up) { 
		font-size: 120%;
	}
}

h1, h2, h3, h4, h5, h6 {
	letter-spacing: 0.06rem;
	font-family: 'Raleway', Arial, Helvetica, sans-serif;
	font-weight: 900;
	margin-bottom: 1.5rem;
}

p {
	margin-bottom: 2.2rem;
}

.screen {
	width: 100vw;
	height: 100vh;
	max-width: 100vw;
	max-height: 100vh;
	overflow: hidden;
	position: relative;
	padding: 30% 0;

	@include breakpoint(medium up) { 
		padding: 12% 0;		
	}
}

#screen-1 {
	background-image: url(../img/records.jpg);
	background-image: url(../img/supraphon.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 90vh;

	.head {
		position: fixed;
		bottom: 10vh;
		left: 1rem;
		line-height: .7;
		color: $colorWhite;
		z-index: 100;
	}
}

#screen-2 {
	background-color: #fff;
	background-color: $colorWhite;
}

#screen-3 {
	background-color: #fff;
}

.head {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	font-size: 8vw;
	padding: 0 0 0 2rem;
	margin: 0 0 0 14vw;	
	//background-color: $colorBrandPrimary;
	text-transform: uppercase;
}


#sleeve,
#vinyl {
	width: 14vw;
	bottom: 10vh;
	left: 1rem;

	img {
		width: 100%;
	}
}

#sleeve {
	z-index: 10;
	position: fixed;
	transform: translateY(32%);
}


#vinyl {
	position: absolute;
	z-index: 9;
	bottom: 10vh;
	margin-bottom: -3.5%;
}

.the-txt {
	position: relative;
	z-index: 1000;
}

.tagline {
	text-align: center;
}

.social a {
	margin: 0 4vw;

	@include breakpoint(medium up) { 
		margin: 0 2vw;
	}
}

.icon {
	width: 30px;
}